import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const fadeToggle = (container, trigger) => { 
  gsap
    .to(container, {
      autoAlpha: 0.25,
      scrollTrigger: {
        trigger: trigger,
        start: `top top`, 
        end: `+=270`,
        scrub: 1
      }
    })
}

export default fadeToggle