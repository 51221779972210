import React, { useRef, useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import ChevronDown from "../Symbols/chevron-down"
import fadeToggle from "../../utils/fadeToggle"

const ScrollMarker = props => {
  const ScrollMarkerRef = useRef(null)
  const { reveal, variant } = props

  useEffect(() => {
    fadeToggle(ScrollMarkerRef.current, props.fadeTrigger)
  }, [])

  return (
    <div
      ref={ScrollMarkerRef}
      className={`scroll-marker-container scroll-marker-container--${variant}`}
    >
      <a
        className={`scroll-marker ${reveal ? `reveal` : ``}`}
        onClick={e => {
          e.preventDefault()
          scrollTo("#main")
        }}
      >
        <ChevronDown />
      </a>
    </div>
  )
}

export default ScrollMarker
